import React from 'react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';

import RouteGuard from './RouteGuard';

import accountInfo from '../utils/accountInfo';
import constantRoutes from './routes/constantRoutes';
import asyncRoutes from './routes/asyncRoutes';
import { findDefaultRoute, processAsyncRoutes } from './utils';

import { RouteInfo } from './types';

class RouterManager {
    asyncRoutes: RouteInfo[] = [];

    defaultRoute: RouteInfo = { path: '/' };

    create() {
        this.generateAsyncRoutes();

        const allRoutes = constantRoutes.reduce((acc, cur) => {
            if (cur.path === '/') {
                return [...acc, { ...cur, children: [...processAsyncRoutes(this.asyncRoutes, '/')] }];
            }
            return [...acc, cur];
        }, [] as RouteInfo[]);
        const index = allRoutes.findIndex((route) => route.path === '/404');
        // 取出404页面，为了放在路由列表的最后
        const route404 = index === -1 ? null : allRoutes.splice(index, 1)[0];
        const redirectRoute = allRoutes.find((route) => route.redirectRoute);
        const redirectPath = redirectRoute?.path || allRoutes[0].path;

        const routeList = this.generateRoutes(allRoutes, redirectPath);
        if (route404 && route404.component) {
            const PageComponent = route404.component;
            routeList.push({
                path: '*',
                element: <PageComponent />,
            });
        }
        return createBrowserRouter(routeList, { basename: process.env.PUBLIC_URL });
    }

    private generateAsyncRoutes() {
        const version = accountInfo.get('version');
        if (version) {
            if (version === 'marketing') {
                this.asyncRoutes = asyncRoutes.filter((route) => route.versions?.includes('marketing'));
            } else {
                this.asyncRoutes = [...asyncRoutes];
            }
            this.defaultRoute = findDefaultRoute(this.asyncRoutes);
        }
    }

    private generateRoutes(list: RouteInfo[], redirect: string): RouteObject[] {
        return list.map((route) => {
            const PageComponent = route.component || (() => <></>);
            return {
                id: route.id,
                path: route.path,
                element: (
                    <RouteGuard redirectPath={redirect}>
                        <PageComponent />
                    </RouteGuard>
                ),
                children:
                    route.children && route.children.length > 0 ? this.generateRoutes(route.children, redirect) : [],
            };
        });
    }
}

export default new RouterManager();
