// REACT_APP_VERSION 在build.js中赋值的
export const APP_VERSION = process.env.REACT_APP_VERSION || 'dev';

export const OSS_PATH = process.env.REACT_APP_OSS_PATH;

export const STORAGE_PREFIX = 'react-admin_';

export const PAGE_MAIN_ID = 'page-main-layout';

export const API_BASE_URL = '/api';

export default {};
