import React, { useState, useMemo, useRef, useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import dayjs from 'dayjs';
import { useUpdateEffect } from 'ahooks';

import { IntlProvider } from 'react-intl';
import antdZhCN from 'antd/locale/zh_CN';
import antdEnUS from 'antd/locale/en_US';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en';
import localeMap from './i18n';

import routerManager from './router';
import { RouterContext, RouterCtx } from './context';

import type { State } from './store/types';
import type { LocaleMap } from './i18n/types';

const antdLocaleMap: LocaleMap<typeof antdZhCN> = {
    zh: antdZhCN,
    en: antdEnUS,
};

const dayLocaleMap: LocaleMap<string> = {
    zh: 'zh-cn',
    en: 'en',
};

export default function App() {
    const locale = useSelector<State, State['locale']>((state) => state.locale);
    const loading = useSelector<State, State['loading']>((state) => state.loading);
    const [routers, setRouters] = useState(() => routerManager.create());
    const intlMsg = useMemo(() => localeMap[locale], [locale]);
    const antdMsg = useMemo(() => antdLocaleMap[locale], [locale]);
    const routersUpdateCallbacks = useRef<Parameters<RouterCtx['onUpdate']>[0][]>([]);
    const [context, setContext] = useState<RouterCtx>({
        defaultRoute: routerManager.defaultRoute,
        asyncRoutes: routerManager.asyncRoutes,
        regenerate: () => {
            setRouters(routerManager.create());
        },
        onUpdate: (cbk) => {
            routersUpdateCallbacks.current.push(cbk);
        },
    });

    useEffect(() => {
        dayjs.locale(dayLocaleMap[locale]);
    }, [locale]);

    useUpdateEffect(() => {
        setContext((prev) => ({
            ...prev,
            defaultRoute: routerManager.defaultRoute,
            asyncRoutes: routerManager.asyncRoutes,
        }));
    }, [routers]);

    useUpdateEffect(() => {
        if (routersUpdateCallbacks.current.length > 0) {
            routersUpdateCallbacks.current.forEach((cbk) => cbk());
            routersUpdateCallbacks.current = [];
        }
    }, [context]);

    return (
        <Suspense fallback={<div />}>
            <IntlProvider locale={locale} messages={intlMsg}>
                <ConfigProvider locale={antdMsg}>
                    <RouterContext.Provider value={context}>
                        <Spin spinning={loading} size="large">
                            <RouterProvider router={routers} />
                        </Spin>
                    </RouterContext.Provider>
                </ConfigProvider>
            </IntlProvider>
        </Suspense>
    );
}
