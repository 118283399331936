import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { Layout, Dropdown, MenuProps } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import type { RouteInfo } from '../../router/types';
import { useRoutePath } from './hooks';
import { ContentContext } from './context';

import styles from './index.module.less';

interface Props {
    routeTree: RouteInfo[];
    routeLink: RouteInfo[];
    defaultRoute: RouteInfo;
}

interface Tab {
    title: string;
    path: string; // 当前路径 如 /page/123
    routePath: string; // 路由配置的路径 如 /page/:id
    search: string;
    component: any;
}

export interface ContentCtx {
    closeTab: (path?: string) => void; // 关闭tab页，不指定path则关闭当前tab页
    routes: RouteInfo[];
}

const menuItems: MenuProps['items'] = [
    { key: 'reload', label: '重新加载' },
    { key: 'closeOther', label: '关闭其他标签' },
    { key: 'closeAll', label: '关闭所有标签' },
];

function isShowInSider(routes: RouteInfo[], path: string) {
    for (let i = 0; i < routes.length; i += 1) {
        const route = routes[i];
        if (route.path === path && !route.hidden) {
            return true;
        }
        if (route.children && route.children.length > 0) {
            const bool = isShowInSider(route.children, path);
            if (bool) {
                return true;
            }
        }
    }
    return false;
}

function removeInvisibleTab(tabs: Tab[], route: RouteInfo) {
    let tabArr = [...tabs];
    if (Array.isArray(route.children)) {
        route.children.forEach((child) => {
            const i = tabArr.findIndex((item) => item.routePath === child.path);
            if (i !== -1) {
                tabArr.splice(i, 1);
            }
            if (child.children && child.children.length > 0) {
                tabArr = removeInvisibleTab(tabArr, child);
            }
        });
    }

    return tabArr;
}

export default function Content(props: Props) {
    const { routeTree, routeLink, defaultRoute } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const outlet = useOutlet();
    const currRoutePath = useRoutePath();
    const [tabs, setTabs] = useState<Tab[]>([]);
    const [currTab, setCurrTab] = useState<Tab | null>(null);
    const [reloadTabKey, setReloadTabKey] = useState('');
    const closeTabPath = useRef('');

    const closeTab = useCallback(
        (path?: string) => {
            let closedTab: Tab | undefined;
            if (path) {
                closedTab = tabs.find((tab) => tab.path === path);
            } else if (currTab) {
                closedTab = currTab;
            }
            if (closedTab) {
                setTabs((oldTabs) => oldTabs.filter((tab) => tab.path !== closedTab?.path));
            }
        },
        [tabs, currTab],
    );

    const context = useMemo(() => ({ closeTab, routes: routeLink }), [closeTab, routeLink]);

    function batchCloseTab(tab?: Tab) {
        if (tab && tab.path === currTab?.path) {
            setTabs([tab]);
        } else if (currTab && currTab?.path === defaultRoute.path) {
            setTabs([currTab]);
        } else {
            setTabs([]);
            let path = '';
            if (tab) {
                path = tab.path;
            } else {
                path = `${defaultRoute.path}${defaultRoute.query ? `?${defaultRoute.query}` : ''}`;
            }
            navigate(path);
        }
    }

    function handleMenuClick(key: string, tab: Tab) {
        switch (key) {
            case 'reload':
                setReloadTabKey(tab.path);
                break;
            case 'closeOther':
                batchCloseTab(tab);
                break;
            case 'closeAll':
                batchCloseTab();
                break;
            default:
                break;
        }
    }

    function handleTabChange(tab: Tab) {
        if (tab.path === currTab?.path) return;
        navigate(`${tab.path}${tab.search}`);
    }

    function handleTabClose(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, tab: Tab) {
        e.stopPropagation();
        const newTabs = tabs.filter((item) => item.path !== tab.path);
        if (newTabs.find((item) => item.path === currTab?.path)) {
            setTabs(newTabs);
        } else {
            closeTabPath.current = tab.path;
            navigate(newTabs[0].path);
        }
    }

    useEffect(() => {
        const { pathname, search } = location;
        const route = routeLink.find((r) => r.path === currRoutePath);
        if (route) {
            const { title = '' } = route;
            setCurrTab((old) => {
                if (old?.path === pathname) {
                    if (old.search !== search) return { ...old, search };
                    return old;
                }
                return { title, path: pathname, routePath: currRoutePath, search, component: outlet };
            });
        } else if (defaultRoute.path !== pathname) {
            navigate(`${defaultRoute.path}${defaultRoute.query ? `?${defaultRoute.query}` : ''}`);
        }
    }, [routeLink, location, currRoutePath, outlet, defaultRoute, navigate]);

    useEffect(() => {
        if (!currTab) return;
        if (closeTabPath.current) {
            // 因下行setTabs回调函数晚触发，通过赋值给新变量来避免回调函数中获取不到值
            const closePath = closeTabPath.current;
            setTabs((oldTabs) => oldTabs.filter((item) => item.path !== closePath));
            closeTabPath.current = '';
            return;
        }
        setTabs((oldTabs) => {
            const curr = oldTabs.find((tab) => tab.path === currTab.path);
            if (!curr) {
                let newTabs = [currTab, ...oldTabs];
                const currRoute = routeLink.find((r) => r.path === currTab.routePath);
                if (currRoute?.children && currRoute.children.length > 0) {
                    newTabs = removeInvisibleTab(newTabs, currRoute);
                }
                const bool = isShowInSider(routeTree, currTab.routePath);
                if (!bool) {
                    const parent = currRoute?.parent;
                    if (parent) {
                        let index = -1;
                        // 尝试匹配当前路由是否有兄弟路由
                        const siblings = parent.children || [];
                        for (let i = 0; i < siblings.length; i += 1) {
                            const sibling = siblings[i];
                            const siblingIndex = oldTabs.findIndex((val) => val.routePath === sibling.path);
                            if (siblingIndex !== -1) {
                                index = siblingIndex;
                                break;
                            }
                        }
                        if (index === -1) {
                            // 尝试匹配当前路由是否有父路由
                            index = oldTabs.findIndex((val) => val.routePath === parent.path);
                        }
                        if (index !== -1) {
                            newTabs = [...oldTabs];
                            // 替换兄弟路由或父路由
                            newTabs.splice(index, 1, currTab);
                        }
                    }
                }
                if (newTabs.length > 8) {
                    newTabs.pop();
                }
                return newTabs;
            }
            // 更新路由参数
            if (curr.search !== currTab.search) {
                return oldTabs.map((tab) => {
                    return tab.path === curr.path ? { ...tab, search: currTab.search } : tab;
                });
            }
            return oldTabs;
        });
    }, [currTab, routeTree, routeLink]);

    useEffect(() => {
        if (reloadTabKey) {
            setReloadTabKey('');
        }
    }, [reloadTabKey]);

    return (
        <Layout.Content className={styles.content}>
            <ContentContext.Provider value={context}>
                <div className={`flex ${styles.tabs}`}>
                    {tabs.map((tab) => (
                        <Dropdown
                            key={tab.path}
                            menu={{ items: menuItems, onClick: ({ key }) => handleMenuClick(key, tab) }}
                            trigger={['contextMenu']}
                        >
                            <div
                                className={`${styles.tab} ${tab.path === currTab?.path ? styles.tab_active : ''}`}
                                onClick={() => handleTabChange(tab)}
                            >
                                {tab.title}
                                {tabs.length > 1 && (
                                    <CloseCircleOutlined
                                        className={styles.icon}
                                        onClick={(e) => handleTabClose(e, tab)}
                                    />
                                )}
                            </div>
                        </Dropdown>
                    ))}
                </div>
                {tabs.map((tab) => {
                    return (
                        <div
                            key={tab.path}
                            className={`${styles.wrap} ${tab.path === currTab?.path ? styles.wrap_active : ''}`}
                        >
                            {reloadTabKey === tab.path ? null : tab.component}
                        </div>
                    );
                })}
            </ContentContext.Provider>
        </Layout.Content>
    );
}
