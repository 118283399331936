import { MENU_REG } from './config';
import { RouteInfo } from './types';

/**
 * 构建路由链表
 * @param routes 所有的路由列表
 * @param parent 父级路由
 * @returns
 */
export function createRouteLink(routes: RouteInfo[], parent?: RouteInfo) {
    for (let i = 0; i < routes.length; i += 1) {
        const route = routes[i];
        // 没有root就默认是顶层
        route.root = parent?.root || route;
        // 没有parent就默认是自身
        route.parent = parent || route;
        if (route.children && route.children.length > 0) {
            createRouteLink(route.children, route);
        }
    }
}

/**
 * 加工路由, 修改原路由对象
 * @param routes 路由列表
 * @param parentPath 父级路径
 * @param parentTitle 父级标题
 * @returns
 */
export function processRoute(routes: RouteInfo[], parentPath?: string, parentTitle?: string) {
    const _routes = [];
    for (let i = 0; i < routes.length; i += 1) {
        const route = routes[i];
        if (!MENU_REG.test(route.path)) {
            if (parentPath) {
                // 拼接完整路径
                route.path = `${parentPath}/${route.path}`;
            }
            if (!route.title && parentTitle) {
                route.title = parentTitle;
            }
        }

        if (route.children && route.children.length > 0) {
            const children = processRoute(
                route.children,
                MENU_REG.test(route.path) ? parentPath : route.path,
                route.title || parentTitle,
            );
            route.children = children;
        }
        _routes.push(route);
    }
    createRouteLink(_routes);
    return _routes;
}

/**
 * 查找默认异步路由
 * @param routes 所有的路由列表
 * @returns
 */
export function findDefaultRoute(routes: RouteInfo[]): RouteInfo {
    const firstRoute = routes[0];
    if (MENU_REG.test(firstRoute.path)) {
        return findDefaultRoute(firstRoute.children || []);
    }
    return firstRoute;
}

/**
 * 处理异步路由
 * @param routes 路由树
 * @param parent 父级路由路径
 * @returns
 */
export function processAsyncRoutes(tree: RouteInfo[], parentPath: string): RouteInfo[] {
    let newTree: RouteInfo[] = [];
    tree.forEach((route: RouteInfo) => {
        // 移除父级菜单路由
        if (!MENU_REG.test(route.path)) {
            // +1为了移除/
            const i = parentPath === '/' ? 1 : parentPath.length + 1;
            const newRoute = {
                ...route,
                path: route.path.startsWith(parentPath) ? route.path.slice(i) : route.path,
            };
            if (route.children && route.children.length > 0) {
                newRoute.children = processAsyncRoutes(route.children, route.path);
            }
            newTree.push(newRoute);
        } else if (route.children && route.children.length > 0) {
            // 不使用父级菜单路由的路径当父级路径
            const childRoutes = processAsyncRoutes(route.children, parentPath);
            newTree = [...newTree, ...childRoutes];
        }
    });

    return newTree;
}

/**
 * 路由树转为一维数组
 * @param tree 路由列表
 * @returns
 */
export function flattenRoutes(tree: RouteInfo[]) {
    let list: RouteInfo[] = [];
    for (let i = 0; i < tree.length; i += 1) {
        const route = tree[i];
        list.push(route);
        if (route.children && route.children.length > 0) {
            list = [...list, ...flattenRoutes(route.children)];
        }
    }
    return list;
}

export default {};
