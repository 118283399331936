import { createContext } from 'react';

import { RouteInfo } from './router/types';

export interface RouterCtx {
    defaultRoute: RouteInfo;
    asyncRoutes: RouteInfo[];
    regenerate: () => void;
    onUpdate: (callback: () => void) => void;
}

export const RouterContext = createContext<RouterCtx>({
    defaultRoute: { path: '/' },
    asyncRoutes: [],
    regenerate: () => {},
    onUpdate: () => {},
});

export default {};
