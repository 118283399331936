import { createSlice } from '@reduxjs/toolkit';

import { STORAGE_PREFIX } from '../../config';

import { GlobalConfig } from '../types';

const KEY = `${STORAGE_PREFIX}config`;
const info = window.localStorage.getItem(KEY);

const slice = createSlice({
    name: 'globalConfig',
    initialState: info ? JSON.parse(info) : {},
    reducers: {
        updateGlobalConfig(state, { payload }: { payload: GlobalConfig }) {
            window.localStorage.setItem(KEY, JSON.stringify(payload));
            return payload;
        },
    },
});

export const { updateGlobalConfig } = slice.actions;

export default slice.reducer;
