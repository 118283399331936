import { STORAGE_PREFIX } from '../config';
import type { UserInfoVO } from '../apis/system';

// all:全版 marketing:营销版
export type AccountVersion = 'all' | 'marketing';

interface Data {
    avatar: string;
    name: string;
    enterpriseName: string;
    userId: string;
    wxAppId: string; // 商城小程序appId
    systemVersion: UserInfoVO['systemVersion'];
}

const KEY = `${STORAGE_PREFIX}accountInfo`;

const versionMap: Record<UserInfoVO['systemVersion'], AccountVersion> = {
    1: 'all',
    2: 'marketing',
};

class AccountInfo {
    private data: Data;

    constructor() {
        const config = window.sessionStorage.getItem(KEY);
        this.data = config ? JSON.parse(config) : {};
    }

    update(data: Data) {
        this.data = { ...data };
        window.sessionStorage.setItem(KEY, JSON.stringify(this.data));
    }

    remove() {
        this.data = {} as Data;
        window.sessionStorage.removeItem(KEY);
    }

    get(key: 'version'): AccountVersion;
    get<T extends keyof Data>(key: T): Data[T];
    get(): Data;
    get(key?: keyof Data | 'version') {
        if (key === 'version') {
            return versionMap[this.data.systemVersion];
        }
        if (key) {
            return this.data[key];
        }
        return this.data;
    }
}

export default new AccountInfo();
