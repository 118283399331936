import { baseService } from './services';

import { PaginationParams, PaginationData, RequestConfig, Gift, MallType } from './types';
import type { ActivityType } from './activity';
import type { ServiceCardType } from './product';

// 1:实物商品 2:服务商品 3:品项商品 4:充值商品 5:满减券 6:无门槛 7:手工商品
export type GoodsType = 1 | 2 | 3 | 4 | 5 | 6 | 7;

// 1:商城商品 2:优惠券
export type GoodsSourceType = 1 | 2;

export interface Goods {
    id: string;
    mallGoodsName: string;
    subhead: string;
    mallGoodsType: GoodsType;
    brandId: string;
    categoryId: string;
    sellingPrice: number;
    hasGift: boolean;
    productList: {
        productName: string;
        productType: 1 | 2; // 1: 产品 2: 项目
        productId: string;
        specs: number;
        specsUnits: string;
        num: number;
        manufacturer: string;
        totalAmount: number;
        cardType: ServiceCardType;
        uniqueKey: string;
    }[];
    giftList: Gift[];
    pictureList: {
        pictureType: number; // 1主图 2详情图
        pictureUrl: string;
    }[];
    remark?: string;
    points: number;
    supportMemberDiscount: boolean;
    maxBuyTime: number;
    mallGoodsNo: string;
    salesVolume: number;
    shareUserId: string;
    hasActivity: boolean;
    baseActivityVO: {
        id: string;
        activityType: ActivityType;
        activityName: string;
        activityPicture: string;
        startTime: string;
        endTime: string;
        hasStoreRestrict: boolean;
    };
    groupPurchaseActivityVO: {
        minPrice: number;
        basePrice: number;
        groupPurchaseGradientList: {
            id: string;
            groupPurchaseActivityId: string;
            gradient: number;
            sellingPrice: number;
            personNum: number;
        }[];
        awardProductVOList: {
            id: string;
            couponId: string;
            productType: 1 | 2; // 1:品项 2:优惠券
            productName: string;
            specs: number;
            specsUnits: string;
            totalNum: number;
        }[];
        joinGroupPersonNum: number;
        ongoingGroupActivityList: {
            id: string;
            memberNum: number;
            nextGradientNum: number;
            differenceNum: number;
            avatarList: string[];
        }[];
    };
    storeType: MallType;
    virtualSales: number;
    activityId: string;
    specialPriceActivityListVO: {
        specialPrice: number;
    };
    expireDays: number;
}

export type ListGoods = Pick<
    Goods,
    | 'id'
    | 'mallGoodsName'
    | 'mallGoodsType'
    | 'hasGift'
    | 'salesVolume'
    | 'points'
    | 'supportMemberDiscount'
    | 'hasActivity'
    | 'baseActivityVO'
> & {
    showPictureUrl: string;
    brandName: string;
    parentCategoryName: string;
    categoryName: string;
    sellingPrice: number;
    isShowHome: boolean;
    isReleased: boolean;
    createTime: string;
    showHomeOrder: number;
    groupPurchaseActivityListVO: {
        minPrice: number;
        basePrice: number;
    };
    specialPriceActivityListVO: {
        specialPrice: number;
    };
    supportMemberDiscount: boolean;
};

export type ListParams = PaginationParams &
    Pick<Goods, 'storeType'> &
    Partial<Pick<ListGoods, 'mallGoodsName' | 'isReleased' | 'isShowHome'>> & {
        createTimeStart?: string;
        createTimeEnd?: string;
        categoryId?: string;
        mallGoodsTypeList?: GoodsType[];
    };

/**
 * 获取商品列表
 * @param params
 * @returns
 */
export function getGoodsList(params: ListParams, config?: RequestConfig) {
    const { mallGoodsTypeList, ...reset } = params;
    let typeQuery = '';
    mallGoodsTypeList?.forEach((item, i, arr) => {
        typeQuery += `mallGoodsTypeList=${item}${i !== arr.length - 1 ? '&' : ''}`;
    });
    return baseService.get<PaginationData<ListGoods>>(`/mallGoods/page${typeQuery ? `?${typeQuery}` : ''}`, {
        ...config,
        params: reset,
    });
}

/**
 * 获取商品详情
 * @param id 商品id
 * @returns
 */
export function getGoods(id: Goods['id'], config?: RequestConfig) {
    return baseService.get<Goods>(`/mallGoods/${id}`, config);
}

/**
 * 删除商品
 * @param id 商品id
 * @returns
 */
export function deleteGoods(id: Goods['id']) {
    return baseService.delete(`/mallGoods/${id}`);
}

export type ActionParams = Partial<
    Pick<Goods, 'id' | 'giftList' | 'points' | 'maxBuyTime' | 'virtualSales' | 'salesVolume' | 'expireDays'>
> &
    Pick<
        Goods,
        | 'mallGoodsName'
        | 'subhead'
        | 'mallGoodsType'
        | 'categoryId'
        | 'sellingPrice'
        | 'hasGift'
        | 'pictureList'
        | 'supportMemberDiscount'
        | 'storeType'
    > & {
        productList: (Pick<Goods['productList'][0], 'productId' | 'num'> &
            Partial<Omit<Goods['productList'][0], 'productId' | 'num'>>)[];
    };

/**
 * 新增商品
 * @param params
 * @returns
 */
export function addGoods(params: ActionParams) {
    return baseService.post(`/mallGoods`, params);
}

/**
 * 更新商品
 * @param params
 * @returns
 */
export function updateGoods(params: ActionParams) {
    return baseService.put(`/mallGoods`, params);
}

/**
 * 更新商品上架状态
 * @param id 商品id
 * @param isReleased 是否上架
 * @returns
 */
export function updateReleaseStatus(id: string, isReleased: boolean) {
    return baseService.put(`/mallGoods/released/${id}/${isReleased}`, null, { loading: false });
}

/**
 * 更新商品首页展示排序
 * @param id 商品id
 * @param sort 排序数字, 0为不首页展示
 * @returns
 */
export function updateHomeShowSort(id: string, sort: number) {
    return baseService.put(`/mallGoods/showHome/${id}/${sort}`, null, { loading: false });
}

export interface GoddsPointParams {
    id: ListGoods['id'];
    points: number;
}

/**
 * 更新商品积分
 * @param params
 * @returns
 */
export function updateGoodsPoint(params: GoddsPointParams) {
    return baseService.put(`/mallGoods/points`, params);
}

export default {};
