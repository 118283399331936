import React, { useContext, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Layout } from 'antd';

import Header from './Header';
import Sider from './Sider';
import Content from './Content';

import staffRole from '../../utils/staffRole';
import { RouterContext } from '../../context';
import { flattenRoutes } from '../../router/utils';
import { PAGE_MAIN_ID } from '../../config';
import { getGlobalConfig } from '../../apis/system';
import { updateGlobalConfig } from '../../store/reducers/globalConfig';

import styles from './index.module.less';

export default function Main() {
    const dispatch = useDispatch();
    const { asyncRoutes, defaultRoute } = useContext(RouterContext);
    const routeTree = useMemo(() => [...asyncRoutes], [asyncRoutes]);
    const asyncRouteLink = useMemo(() => flattenRoutes(asyncRoutes), [asyncRoutes]);

    useEffect(() => {
        getGlobalConfig().then((res) => {
            dispatch(updateGlobalConfig(res.data));
        });
        staffRole.fetchConfig();
    }, [dispatch]);

    return (
        <Layout id={PAGE_MAIN_ID} className={styles.main}>
            <Header />
            <Layout>
                <Sider routeTree={routeTree} routeLink={asyncRouteLink} />
                <Content routeTree={routeTree} routeLink={asyncRouteLink} defaultRoute={defaultRoute} />
            </Layout>
        </Layout>
    );
}
