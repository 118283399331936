import { baseService } from './services';

import { PaginationParams, PaginationData, RequestConfig, QRCodeType } from './types';
import type { CustomerWechatStatus } from './customer';

// 1:其他综合类 2:专业科普类 3:客户种草类 4:品牌宣传类 5:评价背书类 6:品项介绍类 7:营销活动类
export type ArticleType = 1 | 2 | 3 | 4 | 5 | 6 | 7;

interface ArticleListDTO {
    id: string;
    type: ArticleType;
    typeName: string;
    title: string;
    subheadingTitle: string;
    authorName: string;
    coverUrl: string;
    createTime: string;
    viewNum: number;
    viewPeopleNum: number;
    shareNum: number;
    sharePeopleNum: number;
    likeNumber: number;
    likePeopleNum: number;
}

export type ArticleListVO = ArticleListDTO;

export type ArticleListParams = PaginationParams &
    Partial<Pick<ArticleListVO, 'type' | 'title' | 'authorName'>> & {
        createTimeStart?: string;
        createTimeEnd?: string;
    };

/**
 * 获取文章列表
 * @param params
 * @returns
 */
export function getArticleList(params: ArticleListParams, config?: RequestConfig) {
    return baseService.get<PaginationData<ArticleListVO>>(`/article-management/page`, { ...config, params });
}

interface ArticleDTO {
    id: string;
    articleType: ArticleType;
    typeName: string;
    title: string;
    subheadingTitle: string;
    authorName: string;
    coverUrl: string;
    content: string;
    createTime: string;
    relations: {
        relationType: QRCodeType;
        relationId: string;
    }[];
    isLike: boolean;
    isShare: boolean;
    isView: boolean;
    likeNumber: number;
    shareNum: number;
    viewNumber: number;
}

export type ArticleVO = ArticleDTO;

/**
 * 获取文章
 * @param id
 * @returns
 */
export function getArticle(id: ArticleVO['id'], config?: RequestConfig) {
    return baseService.get<ArticleVO>(`/article-management/${id}`, config);
}

/**
 * 删除文章
 * @param id
 * @returns
 */
export function deleteArticle(id: ArticleVO['id']) {
    return baseService.delete(`/article-management/${id}`);
}

export type ArticleActionParams = Pick<
    ArticleVO,
    'id' | 'articleType' | 'title' | 'subheadingTitle' | 'authorName' | 'coverUrl' | 'content' | 'relations'
>;

/**
 * 保存文章
 * @param id
 * @returns
 */
export function saveArticle(params: Optional<ArticleActionParams, 'id'>) {
    return baseService.post(`/article-management`, params);
}

/**
 * 更新文章
 * @param id
 * @returns
 */
export function updateArticle(params: ArticleActionParams) {
    return baseService.put(`/article-management`, params);
}

interface ArticleDataDTO extends ArticleVO {
    viewNum: number;
    viewPeopleNum: number;
    viewStaffNum: number;
    sharePeopleNum: number;
    shareStaffNum: number;
    likePeopleNum: number;
    likeStaffNum: number;
}

export type ArticleDataVO = ArticleDataDTO;

/**
 * 获取文章数据
 * @param id
 * @returns
 */
export function getArticleData(id: ArticleVO['id']) {
    return baseService.get<ArticleDataVO>(`/article-management/data/${id}
    `);
}

interface ArticleDataListDTO {
    id: string;
    customerId: string;
    phone: string;
    customerName: string;
    cityName: string;
    customerItineraryName: string;
    isStaff: boolean;
    viewNum: number;
    shareNum: number;
    lastViewTime: string;
    lastShareTime: string;
    likeNum: number;
    nickname: string;
    avatar: string;
    addWechat: boolean;
    wechatStatus: CustomerWechatStatus;
    mainStoreName: string;
    counselorStaff: string;
    defaultFollowStaffName: string;
}

export type ArticleDataListVO = ArticleDataListDTO;

export type ArticleDataListParams = PaginationParams &
    Partial<Pick<ArticleDataListVO, 'phone' | 'isStaff'>> & {
        id: ArticleVO['id'];
        nickname?: string;
        itineraryId?: string;
        viewTimeStart?: string;
        viewTimeEnd?: string;
        shareTimeStart?: string;
        shareTimeEnd?: string;
    };

/**
 * 获取文章数据列表
 * @param params
 * @returns
 */
export function getArticleDataList(params: ArticleDataListParams) {
    return baseService.get<PaginationData<ArticleDataListVO>>(`/article-management/data-list`, { params });
}

/**
 * 获取文章作者
 * @param name
 * @returns
 */
export function getArticleAuthors(name = '') {
    const params = { name };
    return baseService.get<string[]>(`/article-management/author-names`, { params, loading: false });
}

export default {};
