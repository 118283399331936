import { baseService } from './services';

import { PaginationParams, PaginationData, RequestConfig, BlobData, Gift } from './types';
import type { GoodsType } from './goods';
import type { TaskStatus, TaskType } from './task';
import type { VoucherState, VoucherType, UseScenarios } from './voucher';
import type { ServiceCardType } from './product';
import type { CustomerWechatStatus } from './customer';

// 1:新人礼包活动 2:领券活动 3:小程序团购 4:线下沙龙团约 5:专享礼包 6:特惠
export type ActivityType = 1 | 2 | 3 | 4 | 5 | 6;

// 1:未开始 2:预告中 3:进行中 4:已终止 5:已结束 6:草稿待完善 7:草稿已完善
export type ActivityState = 1 | 2 | 3 | 4 | 5 | 6 | 7;

// 1:待支付 2:已取消 3:进行中 4:已成功 5:已失败
export type ActivityOrderState = 1 | 2 | 3 | 4 | 5;

interface BaseActi {
    id: string;
    corporationId: string;
    activityName: string;
    activityType: ActivityType;
    activityState: ActivityState;
    activityPicture: string;
    terminationReason: string;
    needExposure: boolean;
    exposureTime: string;
    startTime: string;
    endTime: string;
    createTime: string;
    hasStoreRestrict: boolean;
    limitStoreList: string[];
    hasStaffRestrict: boolean;
    terminationTime: string;
    customerGroupType: 1 | 2; // 1:营销客群 2:特定客户
    customerGroupId: string;
    customerPhoneList: string[];
    giftList: Gift[];
    activityListingPicture: string;
    activityJoinTimeConfig: {
        totalJoinNum: number;
        personJoinNum: number;
    };
    createType: 1 | 2; // 1:单独创建 2:营销计划
    advertisingSpace: {
        syncAdvertisingSpace: boolean;
        syncType: 1 | 2; // 1:活动开始前 2:活动开始时
        days: number;
        advertisingSpaceType: 1 | 2; // 1:轮播位 2:动态广告位
        advertisingSpaceImage: string;
    };
}

export type ActiListParams = PaginationParams & {
    activityName?: string;
    activityState?: ActivityState;
    hasPusherTask?: boolean;
    activityTypeList?: ActivityType[];
    activityStateList?: ActivityState[];
    startTimeStart?: string;
    startTimeEnd?: string;
    createTimeStart?: string;
    createTimeEnd?: string;
    customerGroupId?: string;
    activityContent?: string;
};

export interface ActiStopReason {
    terminationReason: string;
    terminationTime: string;
    terminationUserName: string;
}

function formatListParams(
    typeList: ActiListParams['activityTypeList'] = [],
    stateList: ActiListParams['activityStateList'] = [],
) {
    let query = typeList.reduce((acc, cur) => {
        let str = `${acc}${!acc ? '' : '&'}`;
        str += `activityTypeList=${cur}`;
        return str;
    }, '');
    query = stateList.reduce((acc, cur) => {
        let str = `${acc}${!acc ? '' : '&'}`;
        str += `activityStateList=${cur}`;
        return str;
    }, query);
    return query;
}

/**
 * 删除活动
 * @param id 活动id
 * @returns
 */
export function deleteActi(id: string) {
    return baseService.delete(`/activityConfig/${id}`);
}

/**
 * 检测活动是否可以删除
 * @param id 活动id
 * @param type 1:营销活动 2:裂变活动
 * @returns
 */
export function checkActiDeleteable(id: string, type?: 1 | 2) {
    return baseService.get<boolean>(`/activityConfig/checkBeforeDelete/${id}?type=${type}`);
}

/**
 * 获取活动终止理由
 * @param id 活动id
 * @returns
 */
export function getActiStopReason(id: string) {
    return baseService.get<ActiStopReason>(`/activityConfig/termination/${id}`);
}

/**
 * 终止活动
 * @param params
 * @returns
 */
export function stopActi(params: Pick<ActiStopReason, 'terminationReason'> & { id: string }) {
    return baseService.post<ActiStopReason>(`/activityConfig/termination`, params);
}

/**
 * 下载活动数据
 * @param id
 * @returns
 */
export function downloadActiData(id: string) {
    return baseService.get<BlobData>(`/activityConfig/downloadStatisticsData/${id}`, { responseType: 'blob' });
}

export interface ActiData {
    id: string;
    customerName: string;
    customerItineraryName: string;
    cityName: string;
    counselorStaff: string;
    getTime: string;
    couponName: string;
    couponType: VoucherType;
    expiredDate: string;
    couponState: VoucherState;
    validityDate: string;
    phone: string;
    defaultFollowStaffName: string;
    couponStateName: string;
    couponTypeName: string;
    defaultFollowStaffId: string;
    nickname: string;
    avatar: string;
    addWechat: boolean;
    wechatStatus: CustomerWechatStatus;
    mainStoreName: string;
}

export type ActiDataListParams = PaginationParams & {
    id: string;
    type: Extract<ActivityType, 2 | 3 | 5 | 6>;
    customerName?: string;
    phone?: string;
    counselorStaff?: string;
    customerItineraryId?: string;
    couponState?: VoucherState;
    couponType?: 1 | 2; // 1:优惠券 2:商品券
    couponName?: string;
    getTimeStart?: string;
    getTimeEnd?: string;
    validityTimeStart?: string;
    validityTimeEnd?: string;
};

/**
 * 获取活动数据
 * @param params
 * @returns
 */
export function getActiDataList(params: ActiDataListParams) {
    const { id, type, ...reset } = params;
    let url = '';
    switch (type) {
        case 2:
        case 5:
            url = `/activityConfig/utilization-data/${id}`;
            break;
        case 3:
            url = `/groupPurchaseActivity/utilization-data/${id}`;
            break;
        case 6:
            url = `/specialPriceActivity/utilization-data/${id}`;
            break;
        default:
            break;
    }
    return baseService.get<PaginationData<ActiData>>(url, {
        params: {
            ...reset,
            type,
        },
    });
}

interface GroupGradient {
    gradient: number;
    sellingPrice: number;
    personNum: number;
}

export interface GroupActi extends BaseActi {
    sellingPrice: number;
    totalLimit: number;
    participationLimit: number;
    duration: number;
    hasLeaderAward?: boolean;
    groupPurchaseGoodsVO: {
        id: string;
        mallGoodsType: GoodsType;
        subhead: string;
        sellingPrice: number;
        groupPurchaseActivityId: string;
        mallGoodsId: string;
        mallGoodsName: string;
        couponId: string;
        periodValidityStartDate: string;
        periodValidityEndDate: string;
        periodValidity: number;
        isReleased: boolean;
    };
    groupPurchaseGradientList: GroupGradient[];
    groupLeaderParticipationList: string[];
    tagLeaderParticipationList: string[];
    groupMemberParticipationList: string[];
    tagMemberParticipationList: string[];
    groupPurchaseLeaderAwardProductVOList: Gift[];
    customerLeaderLimit: boolean;
    customerLeaderItineraryLimit: boolean;
    customerLeaderTagLimit: boolean;
    customerMemberLimit: boolean;
    customerMemberItineraryLimit: boolean;
    customerMemberTagLimit: boolean;
    expireDays: number;
}

export interface ListGroupActi {
    id: string;
    corporationId: string;
    activityName: string;
    activityType: GroupActi['activityType'];
    activityState: GroupActi['activityState'];
    activityPicture: string;
    activityListingPicture: string;
    terminationReason: string;
    needExposure: boolean;
    exposureTime: string;
    startTime: string;
    endTime: string;
    createTime: string;
    hasStoreRestrict: boolean;
    limitStoreList: string[];
    hasStaffRestrict: boolean;
    terminationTime: string;
    customerGroupType: GroupActi['customerGroupType'];
    customerGroupId: string;
    customerPhoneList: string[];
    giftList: GroupActi['giftList'];
    activityJoinTimeConfig: GroupActi['activityJoinTimeConfig'];
    customerLimit: boolean;
    customerItineraryLimit: boolean;
    customerTagLimit: boolean;
    customerItineraryList: string[];
    customerTagList: string[];
    advertisingSpace: GroupActi['advertisingSpace'];
    groupPurchaseActivityId: string;
    quantityCompletion: number;
    totalLimit: number;
    hasPusherTask: boolean;
    mallGoodsId: string;
    goodsName: string;
    activityPrice: string;
    mallGoodsPrice: number;
    minPrice: number;
    maxGroupPeopleNum: number;
    draft: boolean;
}

/**
 * 获取团购活动列表
 * @param params
 * @returns
 */
export function getGroupActiList(params: ActiListParams, config?: RequestConfig) {
    const { activityTypeList, activityStateList, ...reset } = params;
    const query = formatListParams(activityTypeList, activityStateList);
    return baseService.get<PaginationData<ListGroupActi>>(`/groupPurchaseActivity/page${query ? `?${query}` : ''}`, {
        ...config,
        params: reset,
    });
}

/**
 * 获取团购活动
 * @param id 活动id
 * @returns
 */
export function getGroupActi(id: ListGroupActi['id'], config?: RequestConfig) {
    return baseService.get<GroupActi>(`/groupPurchaseActivity/${id}`, config);
}

export type GroupActiActionParams = Omit<
    GroupActi,
    | 'id'
    | 'groupPurchaseGoodsVO'
    | 'groupPurchaseGradientList'
    | 'groupPurchaseLeaderAwardProductVOList'
    | 'sellingPrice'
    | 'needExposure'
    | 'exposureTime'
    | 'activityState'
    | 'terminationReason'
    | 'corporationId'
    | 'createTime'
> &
    Partial<Pick<GroupActi, 'id'>> & {
        groupPurchaseGoodsVO: Partial<GroupActi['groupPurchaseGoodsVO']>;
        groupPurchaseGradientList: (Pick<GroupGradient, 'personNum' | 'sellingPrice'> &
            Partial<Omit<GroupGradient, 'personNum' | 'sellingPrice'>>)[];
        groupPurchaseLeaderAwardProductVOList: (Pick<Gift, 'productType'> & Partial<Omit<Gift, 'productType'>>)[];
    };

/**
 * 保存团购活动
 * @param params
 * @returns
 */
export function saveGroupActi(params: GroupActiActionParams) {
    return baseService.post<GroupActi['id']>(`/groupPurchaseActivity`, params);
}

/**
 * 更新团购活动
 * @param params
 * @returns
 */
export function updateGroupActi(params: GroupActiActionParams) {
    return baseService.put<GroupActi['id']>(`/groupPurchaseActivity`, params);
}

// 1:团长 2:团员
export type ActiGroupRole = 1 | 2;

export interface ActiGroupOrder {
    id: string;
    activityNo: string;
    groupMemberType: ActiGroupRole;
    customerName: string;
    phone: string;
    isStaff: boolean;
    isPusher: boolean;
    customerItineraryName: string;
    state: ActivityOrderState;
    isPusherGroup: boolean;
    joinTime: string;
    endTime: string;
    payAmount: number;
    successAmount: number;
    orderNo: string;
    refundNo: string;
    groupMemberTypeStr: string;
    stateStr: string;
    defaultFollowStaffName: string;
    staffName: string;
    nickname: string;
    avatar: string;
    addWechat: boolean;
    wechatStatus: CustomerWechatStatus;
    mainStoreName: string;
}

export type ActiGroupOrderListParams = PaginationParams & {
    id: string;
    activityNo?: string;
    orderNo?: string;
    refundNo?: string;
    state?: ActivityOrderState;
    phone?: string;
    groupMemberType?: ActiGroupRole;
    isStaff?: boolean;
    isPusher?: boolean;
    isPusherGroup?: true;
    endTimeStart?: string;
    endTimeEnd?: string;
    customerName?: string;
    counselorStaff?: string;
    defaultFollowStaffName?: string;
};

/**
 * 获取团购活动单数据
 * @param params
 * @returns
 */
export function getActiGroupOrderList(params: ActiGroupOrderListParams) {
    const { id, ...reset } = params;
    return baseService.get<PaginationData<ActiGroupOrder>>(`/groupPurchaseActivity/activityDataPage/${id}`, {
        params: reset,
    });
}

export interface ActiGroupOverviewData {
    id: string;
    activityName: string;
    activityType: ActivityType;
    activityState: ActivityState;
    payedAmount: number;
    successAmount: number;
    failAmount: number;
    refundAmount: number;
    totalGroupNum: number;
    onGoingGroupNum: number;
    successGroupNum: number;
    maxGroupNum: number;
    failedGroupNum: number;
    totalPusherGroupNum: number;
    onGoingPusherGroupNum: number;
    successPusherGroupNum: number;
    failedPusherGroupNum: number;
    totalPeopleNum: number;
    usedNum: number;
    effectiveRate: number;
}

/**
 * 获取团购活动总览数据
 * @param id
 * @returns
 */
export function getActiGroupOverviewData(id: ListGroupActi['id']) {
    return baseService.get<ActiGroupOverviewData>(`/groupPurchaseActivity/activityData/${id}`);
}

export interface VoucherActi extends BaseActi {
    customerCount: number;
    customerGroupName: string;
    activityContent: string;
}

/**
 * 获取券活动
 * @param id 活动id
 * @returns
 */
export function getVoucherActi(id: string, config?: RequestConfig) {
    return baseService.get<VoucherActi>(`/activityConfig/${id}`, config);
}

export type VoucherActiActionParams = Pick<VoucherActi, 'activityName' | 'activityType' | 'startTime' | 'giftList'> &
    Partial<
        Omit<
            VoucherActi,
            | 'activityName'
            | 'startTime'
            | 'giftList'
            | 'customerCount'
            | 'corporationId'
            | 'activityState'
            | 'terminationReason'
            | 'activityJoinTimeConfig'
        >
    > & {
        activityJoinTimeConfig?: Partial<Pick<VoucherActi['activityJoinTimeConfig'], 'personJoinNum' | 'totalJoinNum'>>;
    };

/**
 * 保存券活动
 * @param params
 * @returns
 */
export function saveVoucherActi(params: VoucherActiActionParams) {
    return baseService.post(`/activityConfig/addActivity`, params);
}

/**
 * 更新券活动
 * @param params
 * @returns
 */
export function updateVoucherActi(params: VoucherActiActionParams) {
    return baseService.put(`/activityConfig/updateActivity`, params);
}

export interface VoucherActiOverviewData {
    totalIssueNum: number;
    totalNum: number;
    totalPeopleNum: number;
    usedNum: number;
    effectiveRate: number;
    activityName: string;
    activityState: ActivityState;
}

/**
 * 获取券活动数据总览
 * @param id 活动id
 * @returns
 */
export function getVoucherActiOverviewData(id: string) {
    return baseService.get<VoucherActiOverviewData>(`/activityConfig/utilization-rate/${id}`);
}

export type ListIssueVoucherActi = Pick<
    VoucherActi,
    | 'id'
    | 'activityName'
    | 'customerCount'
    | 'customerGroupType'
    | 'activityState'
    | 'startTime'
    | 'createTime'
    | 'customerGroupName'
    | 'activityContent'
>;

/**
 * 获取发券活动列表
 * @param params
 * @returns
 */
export function getIssueVoucherActiList(params: ActiListParams) {
    const { activityTypeList, activityStateList, ...reset } = params;
    const query = formatListParams(activityTypeList, activityStateList);
    return baseService.get<PaginationData<ListIssueVoucherActi>>(
        `/activityConfig/querySendCouponPage${query ? `?${query}` : ''}`,
        {
            params: reset,
        },
    );
}

export type ListReceiveVoucherActi = Pick<
    VoucherActi,
    | 'id'
    | 'activityName'
    | 'activityType'
    | 'activityState'
    | 'startTime'
    | 'endTime'
    | 'createTime'
    | 'activityContent'
> & {
    totalJoinNum: number;
    currJoinNum: number;
};

/**
 * 获取领券活动列表
 * @param params
 * @returns
 */
export function getReceiveVoucherActiList(params: ActiListParams, config?: RequestConfig) {
    const { activityTypeList, activityStateList, ...reset } = params;
    const query = formatListParams(activityTypeList, activityStateList);
    return baseService.get<PaginationData<ListReceiveVoucherActi>>(
        `/activityConfig/queryCouponGiftPage${query ? `?${query}` : ''}`,
        {
            ...config,
            params: reset,
        },
    );
}

export interface SpecialOfferActi extends BaseActi {
    mallGoodsId: string;
    specialPrice: number;
    mallGoodsName: string;
    mallGoodsType: GoodsType;
    sellingPrice: number;
    customerLimit: boolean;
    customerItineraryList: string[];
    customerTagList: string[];
    customerItineraryLimit: boolean;
    customerTagLimit: boolean;
    expireDays: number;
    isReleased: boolean;
}

export type ListSpecialOfferActi = Pick<
    SpecialOfferActi,
    'id' | 'activityName' | 'activityState' | 'startTime' | 'endTime' | 'createTime' | 'mallGoodsId'
> & {
    hasPusherTask: boolean;
    joinPersonNum: number;
    goodsName: string;
    activityPrice: string;
    mallGoodsPrice: number;
};

/**
 * 获取特惠活动列表
 * @param params
 * @returns
 */
export function getSpecialOfferActiList(params: ActiListParams, config?: RequestConfig) {
    const { activityTypeList, activityStateList, ...reset } = params;
    const query = formatListParams(activityTypeList, activityStateList);

    return baseService.get<PaginationData<ListSpecialOfferActi>>(
        `/specialPriceActivity/page${query ? `?${query}` : ''}`,
        {
            ...config,
            params: reset,
        },
    );
}

/**
 * 获取特惠活动
 * @param id 活动id
 * @returns
 */
export function getSpecialOfferActi(id: ListSpecialOfferActi['id'], config?: RequestConfig) {
    return baseService.get<SpecialOfferActi>(`/specialPriceActivity/${id}`, config);
}

type SpecialOfferActionParams = Pick<
    SpecialOfferActi,
    | 'id'
    | 'activityName'
    | 'startTime'
    | 'endTime'
    | 'hasStaffRestrict'
    | 'hasStoreRestrict'
    | 'limitStoreList'
    | 'mallGoodsId'
    | 'activityPicture'
    | 'specialPrice'
    | 'activityJoinTimeConfig'
    | 'customerLimit'
    | 'customerItineraryList'
    | 'customerTagList'
    | 'customerItineraryLimit'
    | 'customerTagLimit'
    | 'expireDays'
>;

/**
 * 保存特惠活动
 * @param params
 * @returns
 */
export function saveSpecialOfferActi(params: SpecialOfferActionParams) {
    return baseService.post<SpecialOfferActi['id']>(`/specialPriceActivity`, params);
}

/**
 * 更新特惠活动
 * @param params
 * @returns
 */
export function updateSpecialOfferActi(params: SpecialOfferActionParams) {
    return baseService.put<SpecialOfferActi['id']>(`/specialPriceActivity`, params);
}

export interface SpecialOfferOverviewData {
    id: string;
    activityName: string;
    activityType: ActivityType;
    activityState: ActivityState;
    successAmount: number;
    joinCustomerNum: number;
    newCustomerNum: number;
    pusherSuccessAmount: number;
    pusherCustomerJoinNum: number;
    pusherNewCustomerNum: number;
    totalPeopleNum: number;
    usedNum: number;
    effectiveRate: number;
}

/**
 * 获取特惠活动数据总览
 * @param id 活动id
 * @returns
 */
export function getSpecialOfferOverviewData(id: ListSpecialOfferActi['id']) {
    return baseService.get<SpecialOfferOverviewData>(`/specialPriceActivity/activityData/${id}`);
}

export interface SpecialOfferOrder {
    id: string;
    activityNo: string;
    customerName: string;
    phone: string;
    isStaff: boolean;
    isPusher: boolean;
    customerItineraryName: string;
    state: ActivityOrderState;
    isPusherOrder: boolean;
    orderTime: string;
    payAmount: number;
    orderNo: string;
    stateStr: string;
    defaultFollowStaffName: string;
    staffName: string;
    nickname: string;
    avatar: string;
    addWechat: boolean;
    wechatStatus: CustomerWechatStatus;
    mainStoreName: string;
}

export type SpecialOfferOrderListParams = PaginationParams & {
    id: string;
    activityNo?: string;
    orderNo?: string;
    state?: ActivityOrderState;
    phone?: string;
    isStaff?: boolean;
    isPusher?: boolean;
    isPusherOrder?: boolean;
    endTimeStart?: string;
    endTimeEnd?: string;
    customerName?: string;
    counselorStaff?: string;
    defaultFollowStaffName?: string;
};

/**
 * 获取特惠活动订单列表
 * @param params
 * @returns
 */
export function getSpecialOfferOrderList(params: SpecialOfferOrderListParams) {
    const { id, ...reset } = params;
    return baseService.get<PaginationData<SpecialOfferOrder>>(`/specialPriceActivity/activityDataPage/${id}`, {
        params: reset,
    });
}

// 1:领券 2:团购 3:专享礼包 4:特惠 5:群发客户 6:群发朋友圈 7:群发公告 8:推广计划
export type ActivityCalendarType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

// 1. 项目普及 2. 到店福利 3. 转化会员 4. 客户裂变 5. 基础店销 6. 沙龙会销 7. 厂家下店 8. 专家坐诊 9. 自建场景
export type ActivitySceneType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export interface ActivityCalendar {
    type: ActivityCalendarType;
    name: string;
    id: string;
    activityId: string;
    activityType: ActivityType;
    startTime: string;
    endTime: string;
    activityState: ActivityState;
    sendTaskStatus: TaskStatus;
    sceneType: ActivitySceneType;
}

/**
 * 获取活动年历
 * @param year 年份，YYYY格式
 * @returns
 */
export function getActivityCalendarYear(year: string) {
    const params = { year };
    return baseService.get<ActivityCalendar[]>(`/campaignCalendar/annualCalendar`, { params });
}

/**
 * 获取活动月历
 * @param month 月份，YYYY-MM格式
 * @returns
 */
export function getActivityCalendarMonth(month: string) {
    const params = { month };
    return baseService.get<ActivityCalendar[]>(`/campaignCalendar/monthlyCalendar`, { params });
}

export interface ActivityCalendarDayData {
    type: ActivityCalendarType;
    name: string;
    id: string;
    activityId: string;
    activityType: ActivityType;
    startTime: string;
    endTime: string;
    activityState: ActivityState;
    sendTaskStatus: TaskStatus;
    sceneType: ActivitySceneType;
    sendTaskData: {
        totalNum: number;
        totalDealNum: number;
        dealNum: number;
        unDealNum: number;
        progress: string;
    };
    couponActivityData: {
        totalNum: number;
        remainNum: number;
        getNum: number;
        customerNum: number;
        itineraryDataList: {
            itineraryId: string;
            itineraryName: string;
            getNum: number;
            customerNum: number;
        }[];
    };
    groupPurchaseDataList: {
        itineraryId: string;
        itineraryName: string;
        payedAmount: number;
        successAmount: number;
        totalGroupNum: number;
        successGroupNum: number;
        customerNum: number;
    }[];
    specialPriceDataList: {
        itineraryId: string;
        itineraryName: string;
        successAmount: number;
        successOrderNum: number;
        customerNum: number;
    }[];
    promotionData: {
        joinPusherNumber: number;
        orderCustomerNumber: number;
        newCustomerNumber: number;
        orderNumber: number;
        orderAmount: number;
        acquiredCustomerNumber: number;
        acquiredNumber: number;
        pointNumber: number;
        useCustomerNumber: number;
        groupNumber: number;
        successNumber: number;
    };
}

export interface ActivityCalendarDay {
    id: string;
    activityName: string;
    startTime: string;
    endTime: string;
    activityType: ActivityType;
    activityState: ActivityState;
    data: ActivityCalendarDayData[];
}

/**
 * 获取活动当日数据
 * @param date 日期，YYYY-MM-DD格式
 * @returns
 */
export function getActivityCalendarDay(date: string) {
    const params = { time: date };
    return baseService.get<ActivityCalendarDay[]>(`/campaignCalendar/dailyCalendar`, { params, loading: false });
}

export interface CheckActivityResult {
    activityNum: number;
    month: string;
}

/**
 * 创建活动前检测
 * @param month 月份，YYYY-MM格式
 * @returns
 */
export function checkActivityBeforeCreate(month: string) {
    const params = { month };
    return baseService.get<CheckActivityResult>(`/campaignCalendar/checkBeforeCreateActivity`, { params });
}

export interface SuggestActivityDate {
    marketingName: string;
    time: string;
    type: number; // 太多了，懒得定义了
}

/**
 * 获取推荐活动日期
 * @param month 月份，YYYY-MM格式
 * @returns
 */
export function getSuggestActivityDate(month: string) {
    const params = { month };
    return baseService.get<SuggestActivityDate[]>(`/intelligentCampaignCenter/answerQ1`, { params });
}

export interface SuggestActivity {
    activityType: ActivityType;
    tip: string;
    isRecommend: boolean;
}

/**
 * 获取推荐活动
 * @param time YYYY-MM-DD hh:mm:ss格式
 * @param sceneType 活动场景
 * @returns
 */
export function getSuggestActivites(time: string, sceneType: ActivitySceneType) {
    const params = { activityTime: time, activityScene: sceneType };
    return baseService.get<SuggestActivity[]>(`/intelligentCampaignCenter/answerQ3`, { params });
}

export interface SuggestActivityContent {
    id: string;
    name: string;
    type: 1 | 2 | 3; // 1:品项 2:商品 3:优惠券
    limitAmount: number;
    discountAmount: number;
    isRecommend: boolean;
    cardType: ServiceCardType;
    usageScenario: UseScenarios;
    periodValidityStartDate: string;
    periodValidityEndDate: string;
    periodValidity: number;
    num: number;
}

/**
 * 获取推荐活动内容(商品/品项/优惠券)
 * @param time YYYY-MM-DD hh:mm:ss格式
 * @param sceneType 活动场景
 * @param type 活动类型
 * @returns
 */
export function getSuggestActivityContent(time: string, sceneType: ActivitySceneType, type: ActivityType) {
    const params = { activityTime: time, activityScene: sceneType, activityType: type };
    return baseService.get<SuggestActivityContent[]>(`/intelligentCampaignCenter/answerQ4`, { params });
}

export interface ActivityPlanActionParams {
    activityTime: string;
    festivalType?: number;
    activityScene: ActivitySceneType;
    activityType: ActivityType;
    activityItemList: SuggestActivityContent[];
    activityTheme: string;
}

/**
 * 保存营销活动计划
 * @param params
 * @returns
 */
export function saveActivityPlan(params: ActivityPlanActionParams) {
    return baseService.post<string>(`/intelligentCampaignCenter/saveCampaignPlan`, params);
}

export interface ActivityPlanTask {
    id: string;
    type: TaskType;
    taskName: string;
    sendTime: string;
    status: TaskStatus;
    repeatNoticeNum: number;
}

export interface ActivityPlan {
    activityId: string;
    activityType: ActivityType;
    activityState: ActivityState;
    activityName: string;
    activityTimeStart: string;
    activityTimeEnd: string;
    activityNotice: ActivityPlanTask;
    sendCustomerNoticeList: ActivityPlanTask[];
    sendMomentsNoticeList: ActivityPlanTask[];
}

/**
 * 获取营销活动计划
 * @param id
 * @returns
 */
export function getActivityPlan(id: string) {
    return baseService.get<ActivityPlan>(`/intelligentCampaignCenter/getCampaignPlan/${id}`);
}

interface ActivityPlanParams {
    activityId: string;
    cancelNotice: boolean;
    cancelSendCustomer: boolean;
    cancelSendMoments: boolean;
}

/**
 * 确定营销活动计划
 * @param id
 * @returns
 */
export function confirmActivityPlan(params: ActivityPlanParams) {
    return baseService.post(`/intelligentCampaignCenter/confirmCampaignPlan`, params);
}

/**
 * 检测活动是否可终止
 * @param id
 * @returns 0:正常 1:存在推广任务
 */
export function checkActiStopable(id: string) {
    return baseService.post<0 | 1>(`/activityConfig/terminationBefore/${id}`);
}

export default {};
