import locale from './locale';
import loading from './loading';
import salerConfig from './salerConfig';
import globalConfig from './globalConfig';

export default {
    locale,
    loading,
    salerConfig,
    globalConfig,
};
